import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Transactions from "./Transactions";
import Signin from "./examples/Signin";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";

import Preloader from "../components/Preloader";
import Sidebar from "../components/Sidebar";
import Admin from './admin/Admin';
import About from './cms/about/About';
import Blogs from './cms/blogs/Blogs';
import Merchants from './cms/business/Merchants';
import Contact from './cms/contact/Contact';
import Home from './cms/home/Home';
import HowItWork from './cms/howItWork/HowItWork';
import Product from './cms/products/Product';
import WayaPos from './cms/wayapos/WayaPos';
import { history } from '..';
import Settings from './cms/settings/Settings';
import Subscribers from './cms/subscribers/Subscribers';
import Features from './cms/features/Features';
import Agents from './cms/business/Agents';
import Messages from './cms/subscribers/Messages';
import { Spin } from 'antd';
import PushNotification from './cms/notifications/SendNotification';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("user_data")
    if (!data) {
      history.push(Routes.Signin)
    }
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          {/* <Navbar /> */}
          <Component {...props} />
          {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Suspense fallback={<Spin />} >
    <Switch>
      <RouteWithLoader exact path={Routes.Signin} component={Signin} />
      <RouteWithLoader exact path={Routes.ForgotPassword} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword} component={ResetPassword} />
      <RouteWithLoader exact path={Routes.Lock} component={Lock} />
      <RouteWithLoader exact path={Routes.NotFound} component={NotFoundPage} />
      <RouteWithSidebar exact path={Routes.SendNotification} component={PushNotification} />

      <RouteWithSidebar exact path={Routes.Transactions} component={Transactions} />
      <RouteWithSidebar exact path={Routes.Settings} component={Settings} />
      <RouteWithSidebar exact path={Routes.Subscribers} component={Subscribers} />
      <RouteWithSidebar exact path={Routes.Messages} component={Messages} />
      <RouteWithSidebar exact path={Routes.Admin} component={Admin} />
      <RouteWithSidebar exact path={Routes.About} component={About} />
      <RouteWithSidebar exact path={Routes.Blogs} component={Blogs} />
      <RouteWithSidebar exact path={Routes.Merchants} component={Merchants} />
      <RouteWithSidebar exact path={Routes.Agents} component={Agents} />
      <RouteWithSidebar exact path={Routes.Contact} component={Contact} />
      <RouteWithSidebar exact path={Routes.Home} component={Home} />
      <RouteWithSidebar exact path={Routes.HowItWork} component={HowItWork} />
      <RouteWithSidebar exact path={Routes.Product} component={Product} />
      <RouteWithSidebar exact path={Routes.WayaPos} component={WayaPos} />
      <RouteWithSidebar exact path={Routes.Features} component={Features} />
      <Redirect from="/" to={Routes.Signin} />
      <Redirect to={Routes.NotFound} />
    </Switch>
  </Suspense>
);
