import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { createPushNotification } from '../../../services/apiCalls';
import Swal from 'sweetalert2';
// import Layout from "../../components/Layout";

const PushNotification = () => {
    const [category, setCategory] = useState('NEWS');
    const [eventType, setEventType] = useState('PUSH');
    const [userType, setUserType] = useState('PERSONAL');
    const [categoryType, setCategoryType] = useState('');
    const [messageHeader, setMessageHeader] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [file, setFile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log({
            category,
            eventType,
            userType,
            categoryType,
            messageHeader,
            messageBody,
            file
        });
        const postData = {
            eventType,
            category,
            data: {
                message: messageBody,
                type: messageHeader,
                "users": [
                    { "userId": "2829" },
                    { "userId": "28292" }
                ]
            },
            initiator: "Wisdom"
        }
        const data = {
            contentCategory: category,
            data: {
              header: messageHeader,
              message: messageBody,
              type: userType
            },
            eventType,
            "initiator": "ADMIN",
            // "token": "string",
            userType,
            userTypeCategory: categoryType
          }

        const res = await createPushNotification(data)
          console.log({res})
        if (res.status === 200){
            Swal.fire('Done', 'Your request is being processed');
            setMessageBody('')
            setMessageHeader('')
        }
    };
    const categories = [
        'NO_KYC',
        'KYC_LEVEL_1',
        'KYC_LEVEL_2',
        'KYC_LEVEL_3',
        'KYC_LEVEL_4',
        'NO_FIRST_TIME_FUNDING',
        'NO_TRANSACTIONS_IN_THE_PAST_60_DAYS_PLUS',
        'NO_TRANSACTIONS_IN_THE_PAST_30_DAYS_PLUS',
        'NO_TRANSACTIONS_IN_THE_PAST_20_DAYS_PLUS',
        'NO_BILLS_PAYMENT', 
        'N)_LOAN',
        'NO_ATM',
        'NO_POS'
    ]
    return (
        <>
        <div className='card px-5'>
            <h1>Send Push Notification</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="event" className="mb-4">
                <Form.Label>Event Type</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={eventType} onChange={(e) => setEventType(e.target.value)} 
                className='border' style={{ width: '100%', height: '40px', borderRadius: 5}}>
                        <option>...</option>
                        <option value="PUSH">PUSH</option>
                        <option value="SMS">SMS</option>
                        <option value="EMAIL">EMAIL</option>
                        <option value="INAPP">In APP Notification</option>
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="category" className="mb-4">
                <Form.Label>Category</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                <select value={category} onChange={(e) => setCategory(e.target.value)} className='border' style={{ width: '100%', height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        <option value="NEWSLETTER">NEWS LETTER</option>
                        <option value="GENERAL_ANNOUNCEMENT">GENERAL ANNOUNCEMENT</option>
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>User Type</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text> */}
                    <select value={userType} onChange={(e) => setUserType(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        <option value="PERSONAL">PERSONAL</option>
                        <option value="BUSINESS">BUSINESS</option>
                    </select>
                </InputGroup>
              </Form.Group>
              <Form.Group id="user" className="mb-4">
                <Form.Label>User Category</Form.Label>
                <InputGroup>
                {/* <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                    
                </InputGroup.Text> */}
                    <select value={categoryType} onChange={(e) => setCategoryType(e.target.value)} className='border' style={{ width: '100%',height: '40px', borderRadius: 10}}>
                        <option>...</option>
                        {categories.map(e=>
                        <option value={e}>{e}</option>
                        )}
                    </select>
                </InputGroup>
              </Form.Group>

                <Form.Group id="subject" className="mb-4">
                    <Form.Label>Subject</Form.Label>
                    <InputGroup>
                        {/* <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text> */}
                        <Form.Control value={messageHeader} style={{ width:'80%'}} onChange={(e) => setMessageHeader(e.target.value)} name="subject" type="text" placeholder="" />
                    </InputGroup>
                </Form.Group>
                <div>
                    <label>Message Body:</label>
                    <textarea
                        className='px-2'
                        style={{ height: 200, width:'100%'}}
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)} />
                </div>
                {/* <div>
                    <label>Upload File:</label>
                    <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                </div> */}
                <Button variant="primary" type="submit" className="w-100">
                    Send Notification
                </Button>
           </Form>
        </div>
        </>
    );
};

export default PushNotification;